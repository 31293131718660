import { ref, watch, computed } from '@vue/composition-api'
import { getRankingGroups } from '@api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useFilters from '@core/utils/useFilters'

export default function useRankingGroupsList() {
  const listTable = ref([])
  const { t } = useUtils()
  const { hasActionPermission, hasPermission } = usePermissions()
  const { updateFilter, getFilterByModule } = useFilters()

  const tableColumns = computed(() => [
    { text: t('sports.ranking_group').toUpperCase(), value: 'name', show: true },
    { text: t('sports.description').toUpperCase(), value: 'description', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(49, 51, 52),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const searchQuery = ref('')
  const totalListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const filters = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)
  const totalLocal = ref([])

  // fetch data
  const fetchRankingGroups = async () => {
    const response = await getRankingGroups()
    if (response && response.ok) {
      const filterOption = {}
      filters.value = null
      if (searchQuery.value) filterOption.search = searchQuery.value
      filters.value = filterOption

      const filteredData = response.data.filter(
        item =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          item.name.toLowerCase().includes(searchQuery.value) || item.note.toLowerCase().includes(searchQuery.value),
      )

      listTable.value = filteredData
      totalListTable.value = filteredData.length
      totalLocal.value = null
      loading.value = false
    } else {
      listTable.value = []
      totalListTable.value = 0
      loading.value = false
    }
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
    }
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchRankingGroups()
    }, 1000)
  }

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    updateFilter('views-ranking-group-list', 'search', searchQuery.value)
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await fetchRankingGroups()
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'

    return 'primary'
  }

  const resolveStatusText = status => {
    if (status === 'A') return 'Activo'
    if (status === 'I') return 'Inactivo'

    return 'Administrator'
  }

  return {
    listTable,
    tableColumns,
    computedTableColumns,
    searchQuery,
    totalListTable,
    loading,
    options,
    filters,
    totalLocal,
    fetchRankingGroups,
    resolveStatusVariant,
    resolveStatusText,
    hasPermission,
    setFilters,
    setOptions,
    updateFilter,
    getFilterByModule,
  }
}
